import { render, staticRenderFns } from "./customHeader.vue?vue&type=template&id=492d02ad&scoped=true"
import script from "./customHeader.vue?vue&type=script&lang=js"
export * from "./customHeader.vue?vue&type=script&lang=js"
import style0 from "./customHeader.vue?vue&type=style&index=0&id=492d02ad&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "492d02ad",
  null
  
)

export default component.exports