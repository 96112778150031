import { JANUARY, JUNE, DECEMBER } from '@/utils/CONF'

export default {
  computed: {
    nextImputationDate() {
      let year = this.currentClosureDate.year
      let month = this.currentClosureDate.month
      //si juin alors juillet (et non supp06)
      if(this.currentClosureDate.month === JUNE){
          month = this.currentClosureDate.month + 2
      }
      //sinon si décembre alors janvier (et non supp12)
      else if(this.currentClosureDate.month === DECEMBER){
          month = JANUARY
          year = this.currentClosureDate.year +1
      }
      //sinon mois + 1
      else{
          month = this.currentClosureDate.month + 1
      }
      return year.toString() + month.toString().padStart(2, '0')
    }
  }
}
