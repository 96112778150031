<template>
    <div>
        <div>
            {{ label }}
        </div>
        <div class="by-count-content">
            {{ secondLabel }}
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
    name: 'CustomHeader',
    props: {},
    data() {
        return {}
    },
    computed: {
      secondLabel() {
            return this.params.secondLabel
        },
        label() {
            return this.params.label
        },
    },
    methods: {

    },
}
</script>

<style lang="scss" scoped>
.by-count-content {
    margin-top: 7px;
    font-size: 12px;
    color: #8f6c9f;
}
</style>
