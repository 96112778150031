<template>
  <input :ref="'input'" :checked="isChecked" type="checkbox" name="checkbox">
</template>

<script> 
export default {
  name: "CheckBoxRenderVue",
  data() {
    return {
      isChecked: false
    }
  },
  mounted() { 
      this.isChecked = this.params.value
  }
}
</script>

<style scoped>
input {
  width: 17px;
  height: 17px;
  margin-top: 12px;
}
</style>
