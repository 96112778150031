<template>
  <div class="svg" v-if="mood === 'Oui'">
    <v-svg h="2rem">thumbs-up</v-svg>
  </div>
  <div v-else-if="mood === 'Non'" class="svg">
    <v-svg h="2rem">thumbs-down</v-svg>
  </div>
  <div v-else class="svg" /> 
</template>

<script>
  export default {
    name: "moodRenderer",
    data() {
      return {
        mood: 'Happy',
        imgForMood: null,
      };
    },
    methods: {
      refresh(params) {
        this.params = params;
        this.setMood(params);
      },

      setMood(params) {
        this.mood = params.value;
        this.imgForMood = `https://www.ag-grid.com/example-assets/smileys/${
          this.mood === 'Happy' ? 'happy.png' : 'sad.png'
        }`
      },
    },
    created() {
      this.setMood(this.params);
    },
  }
</script>

<style scoped>
  .svg {
    height: 150%;
  }
</style>
